import {
  EuiButton,
  EuiConfirmModal,
  EuiDatePicker,
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiRadioGroup,
  EuiShowFor,
  EuiSpacer,
  EuiTitle,
  htmlIdGenerator,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "app/hooks";
import AddressAutoComplete from "components/AddressAutoComplete";
import { FormInputText } from "components/react-hook-form/FormInput";
import { setFormValues, setInvalid, setRateType, setStale } from "features/raterSlice";
import useQuote from "hooks/queries/useQuote";
import { useAPIURL } from "hooks/useAPIURL";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Quote } from "types/apiTypes";

import authAxios from "../../../../services/authAxios";
import { defaultValues } from "../RCEstimator/RCEstimator";
import RCEstimatorFlyout from "../RCEstimator/RCEstimatorFlyout";
import { ShowRate } from "../components";
import MultiLocationController from "../components/MultiLocationController";
import { PostSaveQuoteModal, SaveQuoteModal } from "../homeowner/HomeownersRater";
import { LPSchema } from "../schemas/Landlords.schema";
import { LPQuoteInput, QuoteLocation } from "../types/Rater.types";
import LocationContent from "./LocationContent";

// type PageParams = {
//   quoteID: string;
// };

const initialLocationValues = {
  streetAddress: "",
  streetAddress2: "",
  city: "",
  state: "",
  zip: "",
  yearBuilt: "",
  isInsuredLocation: "newAddress",
  constructionType: "F",
  protectionClass: "A",
  numFamilies: "1",
  valuation: "ACV",
  perils: "FL1R",
  RCpercentage: "100",
  RCcovA: "0",
  covA: "0",
  covB: "0",
  covC: "0",
  covD: "0",
  deductible: "500",
  forms: [
    {
      formID: htmlIdGenerator()(),
      name: "FL52A",
      fields: undefined,
    },
  ],
  RCEValues: defaultValues,
};

const initialPolicyValues = {
  isBusinessName: "personal",
  businessName: "",
  firstName: "",
  lastName: "",
  streetAddress: "",
  streetAddress2: "",
  city: "",
  state: "",
  zip: "",
  liabilityForm: "None",
  liability: 100000,
  medpay: 5000,
  effectiveDate: moment().format("MM-DD-YYYY"),
  locations: [initialLocationValues],
  forms: [],
};

function LandlordsRater({ quote }: { quote?: Quote }) {
  const queryClient = useQueryClient();
  // const { quoteID } = useParams<PageParams>();
  const [quoteID, setQuoteID] = useState<string | undefined>(undefined);
  const { data, isSuccess } = useQuote(quoteID);
  const [isLoaded, setIsLoaded] = useState(false);
  const [rcLocation, setRCLocation] = useState<number | undefined>(undefined);
  const dispatch = useAppDispatch();
  const [submittedData, setSubmittedData] = useState<LPQuoteInput | undefined>(undefined);
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);
  const submitRateMutation = useMutation((raterData: LPQuoteInput) => {
    return authAxios.post("/quote", { ...raterData, rateType: "LP" });
  });

  const methods = useForm<LPQuoteInput>({
    mode: "onTouched",
    resolver: yupResolver(LPSchema),
    defaultValues: initialPolicyValues,
  });
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = methods;
  const {
    fields: policyForms,
    append: appendPolicyForm,
    remove: removePolicyForm,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `forms` as `forms`, // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const onSubmit: SubmitHandler<LPQuoteInput> = (data) => {
    setSubmittedData(data);
    // console.log("LP Rater Submitted: ", data);
    // authAxios.post("/quote", { ...data, rateType: "LP" }).then((res) => {
    //   console.log("result: ", res);
    // });
  };

  useEffect(() => {
    if (!!quote) {
      methods.reset(quote.details, {
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
        keepDefaultValues: true,
      });
    }
  }, [methods, quote]);

  useEffect(() => {
    dispatch(setRateType("LP"));
    dispatch(setFormValues(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (!isLoaded) {
      if (isSuccess) {
        setIsLoaded(true);
        methods.reset(data?.details);
      }
    }
  }, [data, isSuccess, methods, isLoaded]);

  const debouncedDispatch = useMemo(
    () =>
      _.debounce(
        (value) => {
          LPSchema.validate(value)
            .then((value) => {
              const clone = _.cloneDeep(value);
              dispatch(setFormValues(clone));
            })
            .catch((error) => {
              // console.log(error);
              dispatch(setInvalid());
            });
        },
        500
        // { maxWait: 1000 }
      ),
    [dispatch]
  );

  useEffect(() => {
    const subscription = watch((value, { name, ...type }) => {
      // let clone = _.cloneDeep(value);
      // console.log(clone);
      dispatch(setStale());
      debouncedDispatch(value);
      // handleSubmit(onSubmit2, onError)();
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch, debouncedDispatch]);

  const { getValues } = methods;

  useEffect(() => {
    dispatch(setStale());
    LPSchema.validate(getValues())
      .then((value) => {
        const clone = _.cloneDeep(value);
        dispatch(setFormValues(clone));
      })
      .catch(() => dispatch(setInvalid()));
    return;
  }, [dispatch, getValues]); //TODO Save current rate form in redux on unmount if not saved server

  const handleScroll = () => {
    const position = window.pageYOffset;
    const premiumPanel = document.querySelector("#wcicPremiumPanel");
    if (position >= 60) {
      premiumPanel?.classList.add("wcicFloatingPremium");
    } else {
      premiumPanel?.classList.remove("wcicFloatingPremium");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const defaultURL = useAPIURL();

  let destroyModal;

  if (isDestroyModalVisible) {
    destroyModal = (
      <EuiConfirmModal
        title="Discard Quote Changes?"
        onCancel={() => setIsDestroyModalVisible(false)}
        onConfirm={() => {
          setIsDestroyModalVisible(false);
          methods.reset(undefined, {
            keepErrors: false,
            keepDirty: false,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false,
            keepDefaultValues: true,
          });
        }}
        cancelButtonText="Keep editing"
        confirmButtonText="Discard changes"
        buttonColor="danger"
        defaultFocusedButton="confirm"
      >
        <p>You will lose all unsaved changes made to this quote.</p>
      </EuiConfirmModal>
    );
  }

  return (
    <>
      <EuiPage>
        <FormProvider {...methods}>
          <EuiPageBody>
            <EuiPageHeader pageTitle="Landlord Rater" restrictWidth="1300px" />
            <EuiPageContent
              hasBorder={false}
              hasShadow={false}
              paddingSize="none"
              color="transparent"
              borderRadius="none"
            >
              <EuiPageContentBody restrictWidth="1300px">
                <EuiFlexGroup>
                  <EuiFlexItem grow>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" id="lpraterform">
                      <InsuredForm errors={errors} />

                      <EuiSpacer />
                      <MultiLocationController
                        maxLocations={6}
                        defaultNewLocation={initialLocationValues}
                        onSelectedTabChange={(newTabId, previousTabId) => {
                          methods.trigger(`locations.${previousTabId}` as const);
                        }}
                        locationContent={(selectedTabId) => {
                          return (
                            <LocationContent
                              key={selectedTabId}
                              tabItem={selectedTabId}
                              policyForms={policyForms}
                              appendPolicyForm={appendPolicyForm}
                              removePolicyForm={removePolicyForm}
                              rcButtonClick={(locationNumber) => {
                                setRCLocation(locationNumber);
                              }}
                            />
                          );
                        }}
                      />
                      <EuiSpacer />
                    </form>
                  </EuiFlexItem>
                  <EuiShowFor sizes={["m", "l", "xl"]}>
                    <EuiFlexItem grow={false}>
                      <EuiPanel id="wcicPremiumPanel" hasBorder grow={false} style={{ width: "250px" }}>
                        <EuiTitle>
                          <h2>Premiums</h2>
                        </EuiTitle>
                        <EuiSpacer />
                        <ShowRate />
                        <EuiSpacer />
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiButton type="submit" form="lpraterform">
                              Save and Print
                            </EuiButton>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiButton
                              type="button"
                              color="danger"
                              onClick={() => {
                                setIsDestroyModalVisible(true);
                              }}
                            >
                              Reset
                            </EuiButton>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPanel>
                      <EuiFlexItem grow={false} style={{ width: "250px" }}></EuiFlexItem>
                    </EuiFlexItem>
                  </EuiShowFor>
                </EuiFlexGroup>
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
          {destroyModal}
          <RCEstimatorFlyout
            isFlyoutVisible={rcLocation !== undefined}
            onClose={() => setRCLocation(undefined)}
            onSubmit={(formValues: any, estimatorResult: any) => {
              methods.setValue(`locations.${rcLocation}.RCEValues` as any, formValues);
              // const RCValue = Math.round(estimatorResult / 1000) * 1000;
              methods.setValue(`locations.${rcLocation}.RCcovA` as any, estimatorResult);
              setRCLocation(undefined);
            }}
            defaultValues={{
              ...methods.getValues(`locations.${rcLocation!}.RCEValues` as any),
              name: getValues("firstName") + " " + getValues("lastName"),
              streetAddress: getValues(`locations.${rcLocation!}.streetAddress` as const),
              zip: getValues(`locations.${rcLocation!}.zip` as const),
              constructionYear: getValues(`locations.${rcLocation!}.yearBuilt` as const),
              effectiveDate: getValues("effectiveDate"),
            }}
          />
        </FormProvider>
        {submittedData ? (
          <SaveQuoteModal
            isLoading={submitRateMutation.isLoading}
            onCancelClick={() => {
              setSubmittedData(undefined);
            }}
            onSubmitClick={(comments) => {
              submitRateMutation.mutate(
                { ...submittedData, comments },
                {
                  onSuccess: (data, variables, context) => {
                    queryClient.invalidateQueries("recent_quotes");
                    // console.log("successfully submitted rate", data);
                    setSubmittedData(undefined);
                    setQuoteID(data.data.recordID);
                    // window.open(`http://localhost:5051/print-quote/${data.data.recordID}`, "_blank");
                  },
                  onError: (error, variables, context) => {},
                  onSettled: (data, error, variables, context) => {},
                }
              );
            }}
          />
        ) : null}
        {quoteID ? (
          <PostSaveQuoteModal
            onContinueClick={() => {
              setQuoteID(undefined);
            }}
            onResetClick={() => {
              methods.reset(undefined, {
                keepErrors: false,
                keepDirty: false,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
                keepDefaultValues: true,
              });
              setQuoteID(undefined);
            }}
            onQuotePDFClick={() => {
              window.open(`${defaultURL}/print-quote/${quoteID}`, "_blank");
            }}
            hasRCE={hasRCEValues(methods.getValues("locations"))}
            onQuoteRCEClick={() => {
              window.open(`${defaultURL}/print-rce/${quoteID}`, "_blank");
            }}
          onDogQuestionClick={ ()=>{
          window.open(`${defaultURL}/manuals/Tenant_Occupied_Dog_Questionnaire_2022.pdf`, "_blank");
        }}
          />
        ) : null}
      </EuiPage>
    </>
  );
}

export default LandlordsRater;

const InsuredForm: React.FC<{ errors: any }> = ({ errors }) => {
  const { setValue, watch } = useFormContext();

  return (
    <EuiPanel hasBorder>
      <EuiDescribedFormGroup
        title={<h3>Insured Information</h3>}
        description={<>General information for the insured</>}
        className="wcicRaterDescribedFormGroup"
      >
        <EuiFormRow fullWidth>
          <Controller
            name={`isBusinessName` as any}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <EuiRadioGroup
                options={[
                  { id: "personal", label: "Personal" },
                  { id: "business", label: "Business" },
                ]}
                idSelected={value}
                onChange={(id) => {
                  onChange(id);
                }}
                name="Business Name"
              />
            )}
          />
        </EuiFormRow>

        {watch("isBusinessName") === "personal" ? (
          <>
            <EuiFormRow
              fullWidth
              label="First Name"
              isInvalid={!!errors.firstName?.message}
              error={errors?.firstName?.message}
            >
              <FormInputText
                name={"firstName"}
                label={"First Name"}
                rules={{ required: true }}
                isInvalid={!!errors?.firstName?.message}
                autoComplete={"new-insured-first-name"}
              />
            </EuiFormRow>
            <EuiFormRow
              fullWidth
              label="Last Name"
              isInvalid={!!errors?.lastName?.message}
              error={errors?.lastName?.message}
            >
              <FormInputText
                name={"lastName"}
                label={"Last Name"}
                rules={{ required: true }}
                isInvalid={!!errors?.lastName?.message}
                autoComplete={"new-insured-last-name"}
              />
            </EuiFormRow>
          </>
        ) : (
          <EuiFormRow
            fullWidth
            label="Business Name"
            isInvalid={!!errors.businessName?.message}
            error={errors?.businessName?.message}
          >
            <FormInputText
              name={"businessName"}
              label={"Business Name"}
              rules={{ required: true }}
              isInvalid={!!errors?.businessName?.message}
            />
          </EuiFormRow>
        )}

        <EuiFormRow
          label="Street Address"
          isInvalid={!!errors?.streetAddress?.message}
          error={errors?.streetAddress?.message}
        >
          <Controller
            name={"streetAddress"}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <AddressAutoComplete
                query={value}
                onBlur={onBlur}
                placeholder={""}
                autoComplete={"new-location-address-1"}
                onQueryChange={(search) => {
                  onChange(search);
                }}
                onItemClick={(item) => {
                  onChange(item.data.street_line);
                  setValue("city", item.data.city);
                  setValue("state", item.data.state);
                  setValue("zip", item.data.zipcode);
                }}
              />
            )}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Street Address 2"
          isInvalid={!!errors?.streetAddress2?.message}
          error={errors?.streetAddress2?.message}
        >
          <Controller
            name={"streetAddress2"}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <EuiFieldText
                onChange={onChange}
                onBlur={() => {
                  onBlur();
                }}
                value={value}
                inputRef={ref}
              />
            )}
          />
        </EuiFormRow>
        <EuiFormRow label="City" isInvalid={!!errors?.city?.message} error={errors?.city?.message}>
          <FormInputText
            name={"city"}
            label={"City"}
            rules={{ required: true }}
            autoComplete={"new-location-city"}
            isInvalid={!!errors?.city?.message}
          />
        </EuiFormRow>
        <EuiFormRow fullWidth isInvalid={!!errors?.zip?.message} error={errors?.zip?.message}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ width: 50, minWidth: 50 }}>
              <EuiFormRow label="State" isInvalid={!!errors?.state?.message} error={errors?.state?.message}>
                <FormInputText
                  name={"state"}
                  label={"State"}
                  rules={{ required: true }}
                  maxLength={2}
                  autoComplete={"new-location-state"}
                  isInvalid={!!errors?.state?.message}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ width: 100, minWidth: 100 }}>
              <EuiFormRow label="Zip" isInvalid={!!errors?.zip?.message} error={errors?.zip?.message}>
                <FormInputText
                  name={"zip"}
                  label={"Zip"}
                  autoComplete={"new-location-zip"}
                  rules={{ required: true }}
                  isInvalid={!!errors?.zip?.message}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
        <EuiFormRow
          fullWidth
          label="Effective Date"
          isInvalid={!!errors?.effectiveDate?.message}
          error={errors?.effectiveDate?.message}
        >
          <Controller
            name={"effectiveDate"}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <EuiDatePicker
                scrollableMonthYearDropdown
                selected={moment(value, "MM-DD-YYYY")}
                onChange={(date) => {
                  onChange(date?.format("MM-DD-YYYY"));
                }}
                onBlur={onBlur}
                ref={ref}
              />
            )}
          />
          {/* <FormInputText name={"effectiveDate"} label={"Effective Date"} rules={{ required: true }} isInvalid={!!errors?.effectiveDate?.message} /> */}
        </EuiFormRow>
      </EuiDescribedFormGroup>
    </EuiPanel>
  );
};

function hasRCEValues(locations: Array<QuoteLocation>) {
  return locations.some((element: any) => {
    return element.valuation === "RC";
  });
}
