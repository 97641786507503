import {
  CENTER_ALIGNMENT,
  EuiBottomBar,
  EuiButton,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiDatePicker,
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiRadioGroup,
  EuiShowFor,
  EuiSpacer,
  EuiStat,
  EuiTextArea,
  EuiTitle,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "app/hooks";
import AddressAutoComplete from "components/AddressAutoComplete";
import { FormInputText } from "components/react-hook-form/FormInput";
import { setFormValues, setInvalid, setRateType, setStale } from "features/raterSlice";
import useRater from "hooks/queries/useRater";
import { useAPIURL } from "hooks/useAPIURL";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import authAxios from "services/authAxios";
import { HORaterReturn, Quote } from "types/apiTypes";

import { defaultValues } from "../RCEstimator/RCEstimator";
import RCEstimatorFlyout from "../RCEstimator/RCEstimatorFlyout";
import { ShowRate } from "../components";
import { HOSchema } from "../schemas/Homeowners.schema";
import { HOQuoteInput } from "../types/Rater.types";
import LocationContent from "./LocationContent";

function HomeownersRater({ quote }: { quote?: Quote }) {
  const queryClient = useQueryClient();
  const [rcLocation, setRCLocation] = useState<number | undefined>(undefined);
  const [submittedData, setSubmittedData] = useState<HOQuoteInput | undefined>(undefined);
  const [quoteID, setQuoteID] = useState<string | undefined>(undefined);
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);
  const submitRateMutation = useMutation((raterData: HOQuoteInput) => {
    return authAxios.post("/quote", { ...raterData, rateType: "HO" });
  });
  const dispatch = useAppDispatch();
  const methods = useForm<HOQuoteInput>({
    mode: "onTouched",
    resolver: yupResolver(HOSchema),
    defaultValues: {
      isBusinessName: "personal",
      businessName: "",
      firstName: "",
      lastName: "",
      streetAddress: "",
      streetAddress2: "",
      city: "",
      state: "",
      zip: "",
      effectiveDate: moment().format("MM-DD-YYYY"),
      locations: [
        {
          streetAddress: "",
          streetAddress2: "",
          city: "",
          state: "",
          zip: "",
          isInsuredLocation: "newAddress",
          yearBuilt: "",
          constructionType: "F",
          protectionClass: "A",
          numFamilies: "1",
          valuation: "RC",
          RCpercentage: "100",
          perils: "ML1",
          deductible: "500",
          RCcovA: "0",
          covA: "0",
          covB: "0",
          covC: "0",
          covD: "0",
          liabilityForm: "None",
          liability: 100000,
          medpay: 5000,
          forms: [],
          RCEValues: defaultValues,
        },
      ],
      forms: [],
    },
  });
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
  } = methods;

  const {
    fields: policyForms,
    append: appendPolicyForm,
    remove: removePolicyForm,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `forms` as `forms`, // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  useEffect(() => {
    if (!!quote) {
      methods.reset(quote.details, {
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
        keepDefaultValues: true,
      });
    }
  }, [methods, quote]);

  useEffect(() => {
    dispatch(setRateType("HO"));
    dispatch(setFormValues(undefined));
  }, [dispatch]);

  useEffect(() => {
    const subscription = watch((value, { name, ...type }) => {
      if (name === "locations.0.RCpercentage" || name === "locations.0.RCcovA" || name === "locations.0.valuation") {
        const RCcovA = value.locations?.[0]?.RCcovA || "0";
        const RCpercentage = value.locations?.[0]?.RCpercentage || "0";
        const valuation = value.locations?.[0]?.valuation;

        if (valuation === "RC" || valuation === "IACV") {
          setValue(
            "locations.0.covA",
            String(Math.round((parseInt(RCcovA) * parseInt(RCpercentage)) / 100 / 1000) * 1000)
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const debouncedDispatch = useMemo(
    () =>
      _.debounce(
        (value) => {
          HOSchema.validate(value)
            .then((value) => {
              const clone = _.cloneDeep(value);
              dispatch(setFormValues(clone));
            })
            .catch((error) => {
              console.log(error);
              dispatch(setInvalid());
            });
        },
        500
        // { maxWait: 1000 }
      ),
    [dispatch]
  );

  useEffect(() => {
    const subscription = watch((value, { name, ...type }) => {
      // let clone = _.cloneDeep(value);
      // console.log(clone);
      dispatch(setStale());
      debouncedDispatch(value);
      // handleSubmit(onSubmit2, onError)();
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch, debouncedDispatch]);

  const onSubmit: SubmitHandler<HOQuoteInput> = (data) => {
    setSubmittedData(data);
  };

  useEffect(() => {
    dispatch(setStale());
    HOSchema.validate(getValues())
      .then((value) => {
        const clone = _.cloneDeep(value);
        dispatch(setFormValues(clone));
      })
      .catch(() => dispatch(setInvalid()));
    return;
  }, [dispatch, getValues]); //TODO Save current rate form in redux on unmount if not saved server

  const handleScroll = () => {
    const position = window.pageYOffset;
    const premiumPanel = document.querySelector("#wcicPremiumPanel");
    if (position >= 60) {
      premiumPanel?.classList.add("wcicFloatingPremium");
    } else {
      premiumPanel?.classList.remove("wcicFloatingPremium");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const defaultURL = useAPIURL();

  let destroyModal;

  if (isDestroyModalVisible) {
    destroyModal = (
      <EuiConfirmModal
        title="Discard Quote Changes?"
        onCancel={() => setIsDestroyModalVisible(false)}
        onConfirm={() => {
          setIsDestroyModalVisible(false);
          methods.reset(undefined, {
            keepErrors: false,
            keepDirty: false,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false,
            keepDefaultValues: true,
          });
        }}
        cancelButtonText="Keep editing"
        confirmButtonText="Discard changes"
        buttonColor="danger"
        defaultFocusedButton="confirm"
      >
        <p>You will lose all unsaved changes made to this quote.</p>
      </EuiConfirmModal>
    );
  }

  return (
    <>
      <WatchRaterErrors {...methods} />
      <FormProvider {...methods}>
        <EuiPage>
          <EuiPageBody>
            <EuiPageHeader pageTitle="Homeowners Rater" restrictWidth="1300px" />
            <EuiPageContent
              hasBorder={false}
              hasShadow={false}
              paddingSize="none"
              color="transparent"
              borderRadius="none"
            >
              <EuiPageContentBody restrictWidth="1300px">
                <EuiFlexGroup>
                  <EuiFlexItem grow>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" id="horaterform">
                      <InsuredForm errors={errors} />

                      <EuiSpacer />

                      <LocationContent
                        tabItem={0}
                        policyForms={policyForms}
                        appendPolicyForm={appendPolicyForm}
                        removePolicyForm={removePolicyForm}
                        rcButtonClick={() => setRCLocation(0)}
                      />

                      <EuiSpacer />
                    </form>
                  </EuiFlexItem>
                  <EuiShowFor sizes={["m", "l", "xl"]}>
                    <EuiFlexItem grow={false}>
                      <EuiPanel id="wcicPremiumPanel" hasBorder grow={false} style={{ width: "250px" }}>
                        <EuiTitle>
                          <h2>Premiums</h2>
                        </EuiTitle>
                        <EuiSpacer />
                        <ShowRate />
                        <EuiSpacer />
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <SaveAndPrintButton />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiButton
                              type="button"
                              color="danger"
                              onClick={() => {
                                setIsDestroyModalVisible(true);
                              }}
                            >
                              Reset
                            </EuiButton>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPanel>
                      <EuiFlexItem grow={false} style={{ width: "250px" }}></EuiFlexItem>
                    </EuiFlexItem>
                  </EuiShowFor>
                </EuiFlexGroup>
              </EuiPageContentBody>
              {/* <WatchRCValue /> */}
            </EuiPageContent>
          </EuiPageBody>
          {destroyModal}
          {/* {flyout} */}
        </EuiPage>
        <EuiShowFor sizes={["xs", "s"]}>
          <Bottombar />
        </EuiShowFor>
      </FormProvider>
      {submittedData ? (
        <SaveQuoteModal
          isLoading={submitRateMutation.isLoading}
          onCancelClick={() => {
            setSubmittedData(undefined);
          }}
          onSubmitClick={(comments) => {
            submitRateMutation.mutate(
              { ...submittedData, comments },
              {
                onSuccess: (data, variables, context) => {
                  queryClient.invalidateQueries("recent_quotes");
                  console.log("successfully submitted rate", data);
                  setSubmittedData(undefined);
                  setQuoteID(data.data.recordID);
                },
                onError: (error, variables, context) => {},
                onSettled: (data, error, variables, context) => {},
              }
            );
          }}
        />
      ) : null}
      {quoteID ? (
        <PostSaveQuoteModal
          onContinueClick={() => {
            setQuoteID(undefined);
          }}
          onResetClick={() => {
            methods.reset(undefined, {
              keepErrors: false,
              keepDirty: false,
              keepIsSubmitted: false,
              keepTouched: false,
              keepIsValid: false,
              keepSubmitCount: false,
              keepDefaultValues: true,
            });
            setQuoteID(undefined);
          }}
          onQuotePDFClick={() => {
            //reset
            window.open(`${defaultURL}/print-quote/${quoteID}`, "_blank");
          }}
          hasRCE={
            methods.getValues("locations.0.valuation") === "RC" || methods.getValues("locations.0.valuation") === "IACV"
          }
          onQuoteRCEClick={() => {
            //reset
            window.open(`${defaultURL}/print-rce/${quoteID}`, "_blank");
          }}
          onDogQuestionClick={ ()=>{
            window.open(`${defaultURL}/manuals/Owner_Occupied_Dog_Questionnaire_2022.pdf`, "_blank");
          }}
        />
      ) : null}
      <RCEstimatorFlyout
        isFlyoutVisible={rcLocation !== undefined}
        onClose={() => setRCLocation(undefined)}
        onSubmit={(formValues: any, estimatorResult: any) => {
          methods.setValue(`locations.${rcLocation}.RCEValues` as any, formValues);
          // const RCValue = Math.round(estimatorResult / 1000) * 1000;
          methods.setValue(`locations.${rcLocation}.RCcovA` as any, estimatorResult);
          setRCLocation(undefined);
        }}
        defaultValues={{
          ...methods.getValues(`locations.${rcLocation!}.RCEValues` as any),
          name: getValues("firstName") + " " + getValues("lastName"),
          streetAddress: getValues("locations.0.streetAddress"),
          zip: getValues("locations.0.zip"),
          constructionYear: getValues("locations.0.yearBuilt"),
          effectiveDate: getValues("effectiveDate"),
        }}
      />
    </>
  );
}

export default HomeownersRater;

const Bottombar = () => {
  const {
    data: totalPremium,
    isFetching,
    stale,
    valid,
  } = useRater((data) => {
    return data.total_premium;
  });
  const isLoading = stale || !valid || isFetching;

  return (
    <EuiBottomBar paddingSize="l" position="sticky">
      <EuiStat
        title={totalPremium ? "$" + totalPremium : "$0"}
        titleColor="success"
        description="Total Premium"
        textAlign="right"
        isLoading={isLoading}
      />
    </EuiBottomBar>
  );
};

// const useRateController = () => {
//   const formValues = useAppSelector(selectCurrentFormValues);
//   const { data, isFetching } = useHomeownerRater(formValues);
//   const dispatch = useAppDispatch();
//   useEffect(() => {
//     if (!!data) {
//       dispatch(setRate(data));
//     }
//     dispatch(setFetching(isFetching));
//   }, [data, isFetching]);
// };

const WatchRaterErrors = (methods: UseFormReturn<HOQuoteInput, any>) => {
  const { error } = useRater();
  const { setError, clearErrors } = methods;
  useEffect(() => {
    // console.log("🚀 ~ file: HomeownersRater.tsx ~ line 464 ~ WatchRaterErrors ~ error", error?.response?.data);
    if (error?.response?.data !== undefined) {
      setError(error?.response?.data?.variable, {
        type: "manual",
        message: error?.response?.data?.message,
      });
    } else {
      clearErrors();
    }
  }, [error, setError, clearErrors]);
  return null;
};

interface SaveQuoteModalProps {
  onSubmitClick: (comments: string) => void;
  onCancelClick: () => void;
  isLoading: boolean;
}

export const SaveQuoteModal = ({ onSubmitClick, onCancelClick, isLoading }: SaveQuoteModalProps) => {
  const [comments, setComments] = useState("");
  return (
    <EuiModal onClose={onCancelClick} initialFocus="[name=comments]" maxWidth={"2000px"}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Save Quote</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm>
          <EuiFormRow label="Quote Comments">
            <EuiTextArea
              name="comments"
              placeholder="Comments"
              value={comments}
              onChange={(e) => setComments(e.currentTarget.value)}
              disabled={isLoading}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty disabled={isLoading} onClick={onCancelClick}>
          Cancel
        </EuiButtonEmpty>

        <EuiButton isLoading={isLoading} onClick={() => onSubmitClick(comments)} fill>
          Save
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

interface PostSaveQuoteModalProps {
  onContinueClick: () => void;
  onResetClick: () => void;
  onQuotePDFClick: () => void;
  onQuoteRCEClick: () => void;
  hasRCE: boolean;
  onDogQuestionClick: () => void;
}

export const PostSaveQuoteModal = ({
  onContinueClick,
  onResetClick,
  onQuotePDFClick,
  onQuoteRCEClick,
  hasRCE,
  onDogQuestionClick,
}: PostSaveQuoteModalProps) => {
  return (
    <EuiModal onClose={onContinueClick} maxWidth={"2000px"} css={{ textAlign: CENTER_ALIGNMENT }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Quote Submitted</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <div>You saved a quote!</div>
        <EuiButtonEmpty onClick={onQuotePDFClick}>Open the Quote</EuiButtonEmpty>
        <EuiSpacer size="xs" />
        {hasRCE ? <EuiButtonEmpty onClick={onQuoteRCEClick}>Open the RCE</EuiButtonEmpty> : null}
        {hasRCE ? <EuiSpacer size="xs" /> : null}
        <EuiButtonEmpty onClick={onDogQuestionClick}>Complete Mandatory Dog Questionnaire</EuiButtonEmpty>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onResetClick}>Reset</EuiButtonEmpty>

        <EuiButton onClick={onContinueClick} fill>
          Continue
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

const InsuredForm: React.FC<{ errors: any }> = ({ errors }) => {
  const { setValue, watch } = useFormContext();

  return (
    <EuiPanel hasBorder>
      <EuiDescribedFormGroup
        title={<h3>Insured Information</h3>}
        description={<>General information for the insured</>}
        className="wcicRaterDescribedFormGroup"
      >
        <EuiFormRow fullWidth>
          <Controller
            name={`isBusinessName` as any}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <EuiRadioGroup
                options={[
                  { id: "personal", label: "Personal" },
                  { id: "business", label: "Business" },
                ]}
                idSelected={value}
                onChange={(id) => {
                  onChange(id);
                }}
                name="Business Name"
              />
            )}
          />
        </EuiFormRow>

        {watch("isBusinessName") === "personal" ? (
          <>
            <EuiFormRow
              fullWidth
              label="First Name"
              isInvalid={!!errors.firstName?.message}
              error={errors?.firstName?.message}
            >
              <FormInputText
                name={"firstName"}
                label={"First Name"}
                rules={{ required: true }}
                isInvalid={!!errors?.firstName?.message}
                autoComplete={"new-insured-first-name"}
              />
            </EuiFormRow>
            <EuiFormRow
              fullWidth
              label="Last Name"
              isInvalid={!!errors?.lastName?.message}
              error={errors?.lastName?.message}
            >
              <FormInputText
                name={"lastName"}
                label={"Last Name"}
                rules={{ required: true }}
                isInvalid={!!errors?.lastName?.message}
                autoComplete={"new-insured-last-name"}
              />
            </EuiFormRow>
          </>
        ) : (
          <EuiFormRow
            fullWidth
            label="Business Name"
            isInvalid={!!errors.businessName?.message}
            error={errors?.businessName?.message}
          >
            <FormInputText
              name={"businessName"}
              label={"Business Name"}
              rules={{ required: true }}
              isInvalid={!!errors?.businessName?.message}
            />
          </EuiFormRow>
        )}

        <EuiFormRow
          label="Street Address"
          isInvalid={!!errors?.streetAddress?.message}
          error={errors?.streetAddress?.message}
        >
          <Controller
            name={"streetAddress"}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <AddressAutoComplete
                query={value}
                onBlur={onBlur}
                placeholder={""}
                autoComplete={"new-location-address-1"}
                onQueryChange={(search) => {
                  onChange(search);
                }}
                onItemClick={(item) => {
                  setValue("city", item.data.city, { shouldValidate: true });
                  setValue("state", item.data.state, { shouldValidate: true });
                  setValue("zip", item.data.zipcode, { shouldValidate: true });
                  onChange(item.data.street_line);
                }}
              />
            )}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Street Address 2"
          isInvalid={!!errors?.streetAddress2?.message}
          error={errors?.streetAddress2?.message}
        >
          <Controller
            name={"streetAddress2"}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <EuiFieldText
                onChange={onChange}
                onBlur={(event) => {
                  onBlur();
                }}
                value={value}
                inputRef={ref}
              />
            )}
          />
        </EuiFormRow>
        <EuiFormRow label="City" isInvalid={!!errors?.city?.message} error={errors?.city?.message}>
          <FormInputText
            name={"city"}
            label={"City"}
            autoComplete={"new-location-city"}
            rules={{ required: true }}
            isInvalid={!!errors?.city?.message}
          />
        </EuiFormRow>
        <EuiFormRow fullWidth>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ width: 50, minWidth: 50 }}>
              <EuiFormRow label="State" isInvalid={!!errors?.state?.message} error={errors?.state?.message}>
                <FormInputText
                  name={"state"}
                  label={"State"}
                  autoComplete={"new-location-state"}
                  rules={{ required: true }}
                  maxLength={2}
                  isInvalid={!!errors?.state?.message}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ width: 100, minWidth: 100 }}>
              <EuiFormRow label="Zip" isInvalid={!!errors?.zip?.message} error={errors?.zip?.message}>
                <FormInputText
                  name={"zip"}
                  label={"Zip"}
                  autoComplete={"new-location-zip"}
                  rules={{ required: true }}
                  isInvalid={!!errors?.zip?.message}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
        <EuiFormRow
          fullWidth
          label="Effective Date"
          isInvalid={!!errors?.effectiveDate?.message}
          error={errors?.effectiveDate?.message}
        >
          <Controller
            name={"effectiveDate"}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <EuiDatePicker
                scrollableMonthYearDropdown
                selected={moment(value, "MM-DD-YYYY")}
                onChange={(date) => {
                  onChange(date?.format("MM-DD-YYYY"));
                }}
                onBlur={onBlur}
                ref={ref}
              />
            )}
          />
        </EuiFormRow>
      </EuiDescribedFormGroup>
    </EuiPanel>
  );
};

const SaveAndPrintButton: React.FunctionComponent = () => {
  // const formPremium = useAppSelector((state) => selectFormRate(state, formID), shallowEqual);
  // const isFetching = useAppSelector(selectIsFetching);
  const { data } = useRater((data) => {
    return data.form_errors;
  });
  const formErrors = data as unknown as HORaterReturn["form_errors"] | undefined;
  if (!formErrors || Object.keys(formErrors).length === 0)
    return (
      <EuiButton type="submit" form="horaterform">
        Save and Print
      </EuiButton>
    );
  return (
    <EuiButton disabled={true} type="submit" form="horaterform">
      Save and Print
    </EuiButton>
  );
};
